














import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class SingleCheckboxField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;
  private selectedItems = false;

  @Watch("selectedItems")
  onSelectedItemsChanged(value: any[], oldValue: any[]) {
    this.$emit("input", value);
  }
}
