import mockSchmea from "./mock-schema.json";
import axios, { AxiosInstance, AxiosStatic } from "axios";

/* eslint-disable */
/* (for now)*/
export interface DiversityDataService {
  getLatestSchema(): Promise<any>;
  getSchema(schemaId: number): Promise<any>;
  saveDiversityData(data: any): Promise<SaveSurveyResponse>;
}

export interface SaveSurveyResponse {
  id: string;
}

// TODO: Ensure the $id url resolves
// NOTE: Use camel casing for keys and such

export class MockDiversityDataService implements DiversityDataService {
  private mockSchema: any = mockSchmea;

  /**
   *
   */
  constructor(options?: DiversityDataServiceOptions) {
      
  }

  public getLatestSchema(): Promise<any> {
    return Promise.resolve(this.mockSchema.schema);
  }

  public getSchema(schemaId: number): Promise<any> {
    return Promise.resolve(this.mockSchema.schema);
  }

  public generateSurveyId(): string {
    return MockDiversityDataService.newGuid()
      .split("-")[4]
      .toUpperCase();
  }

  public async saveDiversityData(data: any): Promise<SaveSurveyResponse> {
    return Promise.resolve({ id: this.generateSurveyId() });
  }

  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

export class RestfulDiversityDataService implements DiversityDataService {
  private readonly serviceUrl: string;
  private readonly http: AxiosInstance;
  private readonly schemaPromise: Promise<any>;
  private static cachedSchema: any = null;

  constructor(serviceUrl: string, options?: DiversityDataServiceOptions) {
    const traceId = (options||{}).traceId;

    this.serviceUrl = serviceUrl;
    this.http = axios.create({
      baseURL: serviceUrl,
      headers: {
          'x-appinsights-traceid': traceId
      }
    });
    this.schemaPromise = this.getLatestSchema();
    this.schemaPromise.then(resp => { RestfulDiversityDataService.cachedSchema = resp; })
  }

  public async getLatestSchema() {
    return RestfulDiversityDataService.cachedSchema != null ? (await Promise.resolve(RestfulDiversityDataService.cachedSchema)) : (await this.http.get("demographic_schema/")).data;
  }

  public async getSchema(schemaId: number) {
    return (await this.http.get("demographic_schema/?version=" + schemaId)).data;
  }
  public async saveDiversityData(data: any): Promise<SaveSurveyResponse> {
    return (await this.http.post("demographic_data", data)).data;
  }
}

export interface DiversityDataServiceOptions {
    traceId?: number;
}

export function GetDiversityDataService(options: DiversityDataServiceOptions) {
  return process.env.VUE_APP_DIVERSITY_SERVICE_MOCK
    ? new MockDiversityDataService(options)
    : new RestfulDiversityDataService(process.env.VUE_APP_DIVERSITY_SERVICE_URL, options);
}
