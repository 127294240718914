import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Survey from "@/views/Survey.vue";
import GenerateId from "@/views/GenerateId.vue";
import RouterPrefetch from "vue-router-prefetch";

Vue.use(VueRouter);
Vue.use(RouterPrefetch);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/survey",
    name: "Survey",
    component: Survey
  },
  {
    path: "/generate-id",
    name: "GenerateId",
    component: GenerateId,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
