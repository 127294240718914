import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { DiversityDataServicePlugin } from "./services/DiversityDataServicePlugin";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueScrollTo from "vue-scrollto";
import VueAppInsights from 'vue-application-insights';

import "@/assets/styles/custom.scss";

Vue.config.productionTip = false;

if (process.env.VUE_APP_INSIGHTS_KEY) { 
    Vue.use(VueAppInsights, {
    id: process.env.VUE_APP_INSIGHTS_KEY,
    router
    });
}

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(DiversityDataServicePlugin);
Vue.use(VueReCaptcha, { siteKey: "6LcrLAsaAAAAADYNm4qcj3-LevBO9YrlY-GN33hq" });
Vue.use(VueScrollTo);

//Vue.component("v-select", vSelect);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
